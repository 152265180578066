<template>
    <v-row class="pt-3 px-3">
        <v-col>
            <v-alert
                    dense
                    outlined
                    type="error"
                    class="mb-0"
            >
                {{ $t(documentTitle) + ' ' + $t('message.errors.couldNotBeLoaded') + ' ' + $t('message.contactAppicDeveloper') }}
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DocumentNotLoaded",
    props: ['documentTitle']
}
</script>

<style scoped>

</style>